<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 47 47">
      <g id="Group_759" data-name="Group 759" transform="translate(-20 -645)">
        <g id="Ellipse_36" data-name="Ellipse 36" transform="translate(21 646)" fill="none" stroke="rgba(247,247,247,0.6)" stroke-width="1">
          <circle cx="22" cy="22" r="22" stroke="none"/>
          <circle cx="22" cy="22" r="21.5" fill="none"/>
        </g>
        <g id="like_1_" data-name="like (1)" transform="translate(32.774 657.427)">
          <path :class="isClicked ? 'fill' : 'no-fill'" id="Path_233" data-name="Path 233" d="M20.715,12.826a.6.6,0,0,0,.054-.249V10.115a2.464,2.464,0,0,0-2.461-2.461H13.384V3.961A2.464,2.464,0,0,0,10.923,1.5H9.077a.616.616,0,0,0-.615.615V5.028L6.081,9.195A.605.605,0,0,0,6,9.5v8.615a.616.616,0,0,0,.615.615h9.877a2.461,2.461,0,0,0,2.248-1.462Z" transform="translate(-1.077)" fill="orange"/>
          <path :class="isClicked ? 'fill' : 'no-fill'" id="Path_234" data-name="Path 234" d="M.615,20.346H3.692V10.5H.615A.616.616,0,0,0,0,11.115v8.615A.616.616,0,0,0,.615,20.346Z" transform="translate(0 -1.616)" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: ["isClicked"],
  data() {
    return {}
  }
};
</script>

<style scoped>
svg{
  display:block;
  width:3vw;
  width:var(--icon-width);
  height:3vw;
  height:var(--icon-width);
}
.a,
.d {
  fill: none;
}
.a {
  stroke: rgba(247, 247, 247, 0.6);
}
.b {
  fill: orange;
}
.c {
  stroke: none;
}
</style>